/* color palette */
:root {
  --dark: #222831;
  --blue-grad: #6d7cff;
  --pink-grad: #d863de;
  --dark-grey: #37414f;
  --off-black: #0d0f13;
  --light-purple: #a0aaff;
  --background-red: #833232aa;
}

.alpha-warn {
  color: #a00000;
  font-size: 12px;
}

.background {
  background-color: #f5f5f5;
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  background-image: url("./wheelBackground.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.app {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container {
  margin-top: 56px;
  margin-bottom: 76px;
}

.connectWalletBackground {
  background-color: #f5f5f5;
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  background-image: url("./ConnectWallet.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.title {
  font-family: "Crimson Text", serif;
  font-size: 50px;
  font-weight: bold;
  color: #000000;
}


/* btn primary colors with hover changed*/
.btn-primary {
  color: #fff;
  background-color: #303030;
  border-color: #303030;
}

.btn-outline-primary {
  color: #303030;
  border-color: #303030;
}

.btn-success {
  color: #fff;
  position: relative;
  background-color: transparent;
  border: #fff 1px solid;
  transition: opacity 0.3s ease;
}

.btn-success::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("./chips-blurred.png");
  background-position: center;
  background-size: cover;
  z-index: -1;
  transition: opacity 0.3s ease;
}

.btn-success:hover::before {
  background-color: #8332322c;
  background-image: url("./chips-filtered.png");
  background-position: center;
  background-size: cover;
}

.btn-success:hover {
  color: #fff;
  background-color: #8332322c;
  background-image: url("./chips-filtered.png");
  background-position: center;
  background-size: cover;
  border: #fff 1px solid;
}

.btn-success:active {
  color: #fff;
  background-color: #8332322c;
  background-image: url("./chips-filtered.png");
  background-position: center;
  background-size: cover;
  border: #fff 1px solid;
  box-shadow: none;
}

.btn-success:focus {
  color: #fff;
  background-color: #8332322c;
  background-image: url("./chips-filtered.png");
  background-position: center;
  background-size: cover;
  border: #fff 1px solid;
  box-shadow: none;
}

/* change h1 to override the bootstrap h1 */
.special {
  font-family: "Crimson Text", serif;
  font-size: 60px;
  font-weight: bold;
  color: #000000;
}

.custom-nav-bg {
  background-color: rgb(48, 48, 48, 0.5);
}

.below-nav{
  padding-left: 1rem;
}

.landing-page {
  min-height: 100vh;
  width: 100vw;
  background-color: #833232aa;
  align-items: center;
  justify-content: center;
  display: flex;
  color: #fff;
}

.socmed {
  padding: 30px 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 150px;
}

.socmed .row {
  text-align: center;
}

.logo-background {
  margin-bottom: 30px;
}

.logo-header {
  font-size: 75px;
  font-weight: 600;
  font-family: "Dancing Script", cursive;
}

.navbar-brand {
  font-size: 40px;
  font-weight: 600;
  font-family: "Dancing Script", cursive;
}

.social {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.form-group {
  padding: 8px 0;
}


.card {
  color: #434343;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.7);
  padding: 20px;
  margin-bottom: 20px;
  border: #0b5a10 1px solid;
}

.chain {
  /* position fixed top right */
  position: fixed;
  top: 60px;
  right: 30px;
  z-index: 100;
}

p {
  font-size: 16px;
  line-height: 1.5;
}

.prize-table{
  margin-top: 3px;
  background-color: #fff;
  border-bottom: #000000 1px solid;
  border-top: #0b5a10;
}

.prize-container {
  padding: 0 20px 20px 20px;
}

.prize-headers{
  font-size: 40px;
  color: #fff;
  background-color: #303030;
  font-weight: 600;
  font-family: "Dancing Script", cursive;
  border-radius: 15px 15px 0 0;
  padding-top: 15px;
}

.staker-headers {
  font-size: 17px;
  line-height: 1.5;
}

/* Styles for screens smaller than 768px */
@media (max-width: 767px) {
  .form-group {
    padding: 0;
  }
  .prize-container {
    padding: 0;
  }
  .staker-headers {
    font-size: 15px;
    line-height: 1.4;
  }
  p {
    font-size: 15px;
    line-height: 1.4;
  }
  .navbar-brand {
    font-size: 30px;
  }
  .logo-header {
    font-size: 50px;
  }
  .btn {
    font-size: 14px;
    padding: 5px;
  }
}
